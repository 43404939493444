import styles from "./sidebar.module.scss";
import { NavLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, List } from "@material-ui/core";
import classNames from "classnames";
import { PageKeysEnum, Path } from "enums";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useContext } from "react";
import { FeatureFlagContext } from "contexts";
import { BirReportIcon, SidebarImage, StocqLogo, VoidRecordIcon } from "images";
import { Image } from "components/commons";
import { locale } from "localization/en";
import { ShiftRecordsIcon } from "../../../images";

let Navigation = [
  {
    label: locale.records,
    link: Path.ShiftRecords,
    icon: ShiftRecordsIcon,
    expanded: true,
    items: [
      {
        label: locale.shiftRecords,
        child: true,
        link: Path.ShiftRecords,
        key: PageKeysEnum.SHIFT_RECORDS,
      },
    ],
  },
  {
    label: locale.birReport,
    link: Path.BirReport,
    icon: BirReportIcon,
    expanded: true,
    items: [
      {
        label: locale.salesSummaryReport,
        child: true,
        link: Path.SalesSummary,
        key: PageKeysEnum.SALES_SUMMARY_REPORT,
      },
      {
        label: locale.salesTransactioHistoryReport,
        child: true,
        link: Path.SalesTransaction,
        key: PageKeysEnum.SALES_TRANSACTION_HISTORY_REPORT,
      },
      {
        label: locale.discountReport,
        child: true,
        link: Path.DiscountReport,
        key: PageKeysEnum.DISCOUNT_REPORT,
      },
      {
        label: locale.refundTransactionReport,
        child: true,
        link: Path.RefundTransaction,
        key: PageKeysEnum.REFUND_TRANSACTION_REPORT,
      },
    ],
  },
  {
    label: locale.voidReport,
    link: Path.VoidTransaction,
    icon: VoidRecordIcon,
    exact: true,
    key: PageKeysEnum.VOID_REPORT,
  },
];

const ChildItem = (props) => {
  const { hasFeature } = useContext(FeatureFlagContext);
  const { styles, item } = props;

  const isShowParent = () => {
    if (!item.expanded) return true;

    return item?.items?.some((child) => (child.key ? hasFeature(`PAGE_${child.key}`) : true));
  };

  return (
    isShowParent() && (
      <Accordion elevation={0} className={styles.accordionDetails} defaultExpanded={item.expanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.expandIcon} />}>
          <NavLink
            to={item.link}
            className={classNames(styles.listItem, styles.disableLink)}
            activeClassName={classNames(item, styles.selectedMenu)}
          >
            <span className={styles.item}>
              <span className={styles.listIconRoot}>
                <Image src={item.icon} className={styles.iconCustom} />
              </span>
              <span className={styles.listText}>{item.label}</span>
            </span>
          </NavLink>
        </AccordionSummary>
        <AccordionDetails>
          <div className={styles.child}>
            {item.items
              ?.filter((child) => {
                return child.key ? hasFeature(`PAGE_${child.key}`) : true;
              })
              .map((child, childKey) => {
                return (
                  <NavLink
                    key={childKey}
                    to={child.link}
                    exact={child.exact}
                    className={classNames(styles.listItem)}
                    activeClassName={styles.active}
                  >
                    <span className={styles.listIconRoot}>
                      {/* <Image src={PriceTracqLogo} className={styles.logo} /> */}
                    </span>
                    <span className={styles.listText}>{child.label}</span>
                  </NavLink>
                );
              })}
          </div>
        </AccordionDetails>
      </Accordion>
    )
  );
};

const Sidebar = () => {
  const { hasFeature } = useContext(FeatureFlagContext);

  return (
    <div className={classNames(styles.sidebarContainer)}>
      <div className={styles.logoContainer}>
        <Image src={StocqLogo} className={styles.logo} />
      </div>
      <div className={styles.smallColorBox}></div>
      <List className={styles.sidebarList}>
        {Navigation.filter((item) => {
          return item.key ? hasFeature(`PAGE_${item.key}`) : true;
        }).map((item, index) => {
          return (
            <span key={index}>
              {item.exact ? (
                <NavLink
                  to={item.link}
                  exact
                  className={classNames(styles.listItem)}
                  activeClassName={styles.active}
                >
                  <span className={styles.item}>
                    <span className={styles.listIconRoot}>
                      <Image src={item.icon} className={styles.iconCustom} />
                    </span>
                    <span className={styles.listText}>{item.label}</span>
                  </span>
                </NavLink>
              ) : (
                <ChildItem styles={styles} item={item} />
              )}
            </span>
          );
        })}
      </List>
      <div>
        <Image src={SidebarImage} className={styles.sidebar_image} />
      </div>
    </div>
  );
};

export default Sidebar;
